import { render, staticRenderFns } from "./sellasset.vue?vue&type=template&id=06ab9daa&scoped=true&"
import script from "./sellasset.vue?vue&type=script&lang=js&"
export * from "./sellasset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ab9daa",
  null
  
)

export default component.exports