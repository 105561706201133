<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Vente de tokens sur le marché secondaire</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Une personne désireuse de vendre un token en utilisant l'unité de compte de l'écosystème Fraction.re (le fcEURO)
			va émettre un ordre de vente sur la place de marché. Lorsqu'un acheteur fait une offre d'achat d'un montant identique ou supérieur
			celui-ci s'execute et les deux comptes sont crédités.
			<br />
			<br />
			L'ordre de vente est très simple à soumettre, il suffit d'indiquer la quantité à vendre et valider. Par défaut, le prix unitaire correspond au cours de référence du token, mais il peut être modifié.


		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Léa veut vendre 10 tokens RE59100F001.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-1.png" />
				<br /> <br /> <b> Le compte de Léa</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre d'achat pour 10 RE59100F001 </h3>


		<p>Dans la section <b>Vente</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-2.png" />
				<br /> <br />
				<b>Léa sélectionne le menu Vente et arrive sur la liste des tokens disponibles s'affiche
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-3.png" /><br />
				<br /> <br />
				<b>Léa sélectionne le token RE59100F001, le menu des ordres s'affiche, elle clique sur ORDRE DE VENTE
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-4.png" /><br />
				<br /> <br />
				<b>Léa indique le nombre de token à acheter, conserve le prix du jour et valide.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-5.png" /><br />
				<br /> <br />
				<b> Son ordre de vente s'affiche, il est en attente de validation par la blockchain
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/sell-asset-6.png" /><br />
				<br /> <br />
				<b>Un acheteur avait aussi passé un ordre, la transaction a été validée, Léa a vendu ses 10 tokens.

				</b> <br />
			</div>


		</div>




		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Léa a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre de vente
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>
	</div>



</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
